var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--dot justify-center"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("Contact Us")])]), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "h-100",
    attrs: {
      "tile": "",
      "flat": "",
      "color": "grey-f8"
    }
  }, [_c('div', {
    staticClass: "pa-30 pa-md-50 pa-lg-60"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--line text-center mb-16 mb-lg-24"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("Customer Support")])]), _c('div', [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey-16--text"
  }, [_vm._v("overseas@dentium.com")])]), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "height": "16px",
      "align-self": "center"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey-16--text"
  }, [_vm._v("Mon-Fri")])]), _c('v-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    staticStyle: {
      "height": "16px",
      "align-self": "center"
    },
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey-16--text"
  }, [_vm._v("8AM – 17PM (KST)")])])], 1)], 1)]), _c('v-img', {
    attrs: {
      "src": "/images/sub/contact/contact-img.png"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "d-none d-md-block ml-n1"
  }, [_c('div', {
    staticClass: "h-100 py-30 py-md-50 py-lg-60"
  }, [_c('v-divider', {
    staticClass: "h-100",
    staticStyle: {
      "position": "relative",
      "z-index": "1",
      "border-color": "#e7e7e6"
    },
    attrs: {
      "vertical": ""
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    staticClass: "h-100",
    attrs: {
      "tile": "",
      "flat": "",
      "color": "grey-f8"
    }
  }, [_c('div', {
    staticClass: "px-30 pt-50 pb-30 pa-md-50 px-lg-70 py-lg-60"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--line text-center mb-16 mb-lg-24"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("General Inquiries")])]), _c('form-input', {
    attrs: {
      "code": "general-inquiry",
      "skin": "general-inquiry"
    }
  })], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }