<template>
    <client-page>
        <v-container>
            <div class="tit-wrap tit-wrap--dot justify-center">
                <h2 class="tit">Contact Us</h2>
            </div>
            <v-row no-gutters>
                <v-col cols="12" md="6">
                    <v-card tile flat color="grey-f8" class="h-100">
                        <!-- <div class="card-hover__bg" style="background-image: url(/images/sub/contact/speack-bg.png)"></div> -->
                        <div class="pa-30 pa-md-50 pa-lg-60">
                            <div class="tit-wrap tit-wrap--line text-center mb-16 mb-lg-24">
                                <h2 class="tit tit--sm">Customer Support</h2>
                            </div>

                            <div>
                                <v-row align="center" justify="center" class="row--small">
                                    <v-col cols="auto">
                                        <p class="page-text page-text--lg grey-16--text">overseas@dentium.com</p>
                                    </v-col>
                                    <v-col cols="auto" class="d-flex">
                                        <v-divider vertical style="height: 16px; align-self: center" />
                                    </v-col>
                                    <v-col cols="auto">
                                        <p class="page-text page-text--lg grey-16--text">Mon-Fri</p>
                                    </v-col>
                                    <v-col cols="auto" class="d-flex">
                                        <v-divider vertical style="height: 16px; align-self: center" />
                                    </v-col>
                                    <v-col cols="auto">
                                        <p class="page-text page-text--lg grey-16--text">8AM – 17PM (KST)</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <v-img src="/images/sub/contact/contact-img.png" />
                    </v-card>
                </v-col>
                <v-col class="d-none d-md-block ml-n1">
                    <div class="h-100 py-30 py-md-50 py-lg-60">
                        <v-divider vertical class="h-100" style="position:relative;z-index:1;border-color:#e7e7e6;" />
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card tile flat color="grey-f8" class="h-100">
                        <!-- <div class="card-hover__bg" style="background-image: url(/images/sub/contact/contact-bg.png)"></div> -->
                        <div class="px-30 pt-50 pb-30 pa-md-50 px-lg-70 py-lg-60">
                            <div class="tit-wrap tit-wrap--line text-center mb-16 mb-lg-24">
                                <h2 class="tit tit--sm">General Inquiries</h2>
                            </div>
                            <form-input code="general-inquiry" skin="general-inquiry" />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        FormInput,
    },
};
</script>

<style lang="scss" scoped>
.card-hover {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0 !important;
    box-shadow: none !important;
    overflow: hidden;
    &__bg {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        display: block;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 20px 20px;
        text-align: center;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .card-hover {
        &__content {
            height: 100%;
            padding: 30px 20px;
        }
    }
}
@media (min-width: 1024px) {
    .card-hover {
        &:hover {
            .card-hover {
                &__bg {
                    transform: translate(-50%, -50%) scale(1.2);
                }
            }
        }
        &__content {
            min-height: 600px;
            padding: 60px 20px;
        }
    }
}
@media (min-width: 1200px) {
}

::v-deep {
    .v-text-field .v-label {
        background: var(--v-grey-f8-base);
    }
}
</style>
